/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import thumb from '../../assets/images/Product-2.png';
import procIcon1 from '../../assets/images/proc-icon-1.png';
import procIcon6 from '../../assets/images/proc-icon-8.png';
import procIcon3 from '../../assets/images/proc-icon-7.png';
import procIcon4 from '../../assets/images/proc-icon-4.png';
import procIcon5 from '../../assets/images/proc-icon-5.png';
// import Pdf from '../../assets/uploads/white-paper.pdf';

function ProductTwo() {
    return (
        <>
            <section className="appie-traffic-area traffic-area-2 pt-100" id="eon-longevity">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-xl-3 col-md-12 product-bg">
                            <div className="products product-image product-image-2">
                                <img src={thumb} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-8 col-xl-9 product-padding">
                            <div className="products-content">
                                <div className="appie-traffic-title">
                                    <h1 className="title heading">
                                        <span className="product-eon">eon</span> Longevity
                                    </h1>
                                    <div className="product-main-content">
                                        Proprietary, all-natural, herbal blend with super
                                        ingredients known to deliver a host of benefits.
                                        <ul className="product-list">
                                            <li>Suitable for pregnant or nursing women.</li>
                                            <li>Contains the original herbal Longevity Blend™.</li>
                                            <li>
                                                Suitable for individuals prescribed to
                                                anti-coagulants, and for those scheduled to undergo
                                                surgery.
                                            </li>
                                            <li>
                                                Professionally certified by the Medical Wellness{' '}
                                                Association.
                                            </li>
                                        </ul>
                                    </div>
                                    <br />
                                    <h4 className="sub-title">The blend is:</h4>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-12 col-md-12">
                                        <ul className="products-review">
                                            <li>
                                                <div className="product-review-item">
                                                    <div className="product-icon product-content">
                                                        <img src={procIcon1} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="product-review-item">
                                                    <div className="product-icon product-content">
                                                        <img src={procIcon4} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="product-review-item">
                                                    <div className="product-icon product-content last-child">
                                                        <img src={procIcon5} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="product-review-item">
                                                    <div className="product-icon product-content">
                                                        <img src={procIcon3} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="product-review-item">
                                                    <div className="product-icon product-content">
                                                        <img src={procIcon6} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="traffic-btn">
                                            <a
                                                className="main-btn mr-10 subscribe"
                                                target="_blank"
                                                href="/signup"
                                            >
                                                Sign me up <i className="fal fa-arrow-right" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ProductTwo;
