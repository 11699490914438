import React from 'react';
import blogOne from '../../assets/images/blogOne.png';
import blogTwo from '../../assets/images/blogTwo.png';
import blogThree from '../../assets/images/blogThree.png';
import blogFour from '../../assets/images/blogFour.png';

function Blog() {
    return (
        <>
            <section className="appie-blog-area pb-100">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title heading">
                                    How to Enjoy <span className="blog-heading-eon">eon</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content">
                        <div className="col-lg-2 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={blogOne} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">
                                        Open one stick of <span className="blog-eon">eon.</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={blogTwo} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">
                                        Pour into 8–12 oz (230–350 ml) of hot water, or 10–16 oz
                                        (300–450 ml) of cold water.
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={blogThree} alt="" />
                                </div>
                                <div className="content">
                                    <h3 className="title">Stir until fully dissolved.</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6">
                            <div
                                className="appie-blog-item mt-30 wow animated fadeInUp"
                                data-wow-duration="3000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="thumb">
                                    <img src={blogFour} alt="" />
                                </div>
                                <div className="content">
                                    <h5 className="title">
                                        Enjoy your <span className="blog-eon">eon!</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 added-tips">
                            <div className="tips">
                                <p>
                                    <strong>Added Tips:</strong>
                                    <ul>
                                        <li>
                                            • For optimal benefits, drink at least one stick of{' '}
                                            <span className="blog-tips-eon">eon</span> per day.
                                        </li>
                                        <li>
                                            • For preferred taste, feel free to alter the amount of
                                            water, or add <span className="blog-tips-eon">eon</span>{' '}
                                            to any beverage of your choice.
                                        </li>
                                        <li>
                                            • Drink <span className="blog-tips-eon">eon</span>{' '}
                                            during or after a workout.
                                        </li>
                                        <li>
                                            • Enjoy <span className="blog-tips-eon">eon</span> on
                                            the go!
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Blog;
