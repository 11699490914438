/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Link } from 'react-scroll';
import logo from '../../assets/images/footer-logo.png';
// import Pdf from '../../assets/uploads/white-paper.pdf';

function FooterHomeThree({ className }) {
    return (
        <>
            <section className={`appie-footer-area appie-footer-3-area ${className}`}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-2 col-lg-3 col-md-3">
                            <div className="footer-about-widget footer-about-widget-3 text-center">
                                <div className="footer-logo mt-30">
                                    <img src={logo} alt="" />
                                </div>
                                <p>Unlock Your Longevity</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-1 col-md-2"></div>
                        <div className="col-xl-2 col-lg-2 col-md-2">
                            <div className="footer-navigation footer-navigation-3">
                                <h4 className="title">Products</h4>
                                <ul>
                                    <li>
                                        <Link to="eon-longevity-plus" className="hover-links">
                                            <span className="footer-product-eon">eon</span>{' '}
                                            Longevity +Plus
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="eon-longevity" className="hover-links">
                                            <span className="footer-product-eon">eon</span>{' '}
                                            Longevity
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2">
                            <div className="footer-navigation footer-navigation-3">
                                <h4 className="title">Learn More</h4>
                                <ul>
                                    <li>
                                        <Link to="clinical-study" className="hover-links">
                                            The Clinical Study
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-3">
                            <div className="footer-widget-info">
                                <h4 className="title">Get in Touch</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:info@eon-longevity.com" target="_blank">
                                            <i className="fal fa-envelope" /> info@eon-longevity.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:pr@eon-longevity.com" target="_blank">
                                            <b>
                                                <i className="fal fa-envelope" /> Press Inquiries:{' '}
                                            </b>
                                            pr@eon-longevity.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-about-widget footer-about-widget-3">
                                <div className="social mt-2">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/eonlongevity/"
                                                target="_blank"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://instagram.com/eon_longevity?igshid=YmMyMTA2M2Y="
                                                target="_blank"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://twitter.com/eon_longevity"
                                                target="_blank"
                                            >
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            {/* <div className=" footer-copyright d-flex align-items-center justify-content-between pt-35"> */}
                            <div className="copyright-text footer-copyright text-center">
                                <p>
                                    Copyright © 2022 <span className="copyright-eon">eon</span>. All
                                    Rights Reserved.
                                </p>
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeThree;
