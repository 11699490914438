import React, { useEffect } from 'react';
import logo from '../../assets/images/eon_logo.png';
import StickyMenu from '../../lib/StickyMenu';

function Header() {
    useEffect(() => {
        StickyMenu();
    });
    return (
        <>
            <header className="appie-header-area appie-header-3-area appie-sticky">
                <div className="container-fluid">
                    <div className="header-nav-box header-nav-box-3">
                        <div className="row align-items-center text-center">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 order-1 order-sm-1">
                                <div className="appie-logo-box">
                                    <a href="/">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mb-80"></div>
        </>
    );
}

export default Header;
