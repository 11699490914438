import React from 'react';
import signupImage from '../../assets/images/signup-bg.png';

function Forms() {
    return (
        <>
            <section className="contact-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4 signup-col">
                            <div className="signup-image">
                                <img src={signupImage} alt="" />
                            </div>
                        </div>
                        <div className="col-md-8 ">
                            <div className="contact-form">
                                <h4 className="mb-3">Sign up now and enjoy...</h4>
                                <p>
                                    <ul className="signup-list">
                                        <li>
                                            <svg
                                                fill="#000000"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="24px"
                                                height="24px"
                                            >
                                                <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                            </svg>{' '}
                                            exclusive offers
                                        </li>
                                        <li>
                                            <svg
                                                fill="#000000"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="24px"
                                                height="24px"
                                            >
                                                <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                            </svg>{' '}
                                            <span className="sign-form-eon">eon</span> updates
                                        </li>
                                    </ul>
                                </p>
                                <form
                                    action="https://finehh.us4.list-manage.com/subscribe/post?u=d0fcda2a03adde785e9b46883&amp;id=e8eb1fc39a&amp;f_id=00c306e9f0"
                                    method="post"
                                    id="mc-embedded-subscribe-form"
                                    name="mc-embedded-subscribe-form"
                                    className="validate"
                                    target="_blank"
                                    noValidate
                                >
                                    <div className="col-md-12">
                                        <input
                                            type="email"
                                            name="EMAIL"
                                            placeholder="Email Address"
                                            required
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <input
                                            type="submit"
                                            name="submit"
                                            value="Sign me up"
                                            className="subscribe"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Forms;
