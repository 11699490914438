import React from 'react';
import useToggle from '../../Hooks/useToggle';
import Forms from './Forms';
import Drawer from '../Mobile/Drawer';
import Footer from '../Home/Footer';
import Header from '../Home/Header';

function Contact() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <Forms />
            <Footer />
        </>
    );
}

export default Contact;
