import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import Footer from './Footer';
import Header from './Header';
import HeroSection from './HeroSection';
import Slider from './Slider';
import ProductOne from './ProductOne';
import ProductTwo from './ProductTwo';
import Services from './Services';
import Testimonial from './Testimonial';
import Blog from './Blog';

function HomeThree() {
    const [drawer, drawerAction] = useToggle(false);
    return (
        <>
            <Drawer drawer={drawer} action={drawerAction.toggle} />
            <Header action={drawerAction.toggle} />
            <HeroSection />
            <Slider />
            <ProductOne />
            <ProductTwo />
            <Services />
            <Testimonial />
            <Blog />
            <Footer />
            <BackToTop className="back-to-top-3" />
        </>
    );
}

export default HomeThree;
