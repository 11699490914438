import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import slider1 from '../../assets/images/slider-1.png';
import slider2 from '../../assets/images/slider-3.png';
import slider3 from '../../assets/images/slider-5.png';
import slider4 from '../../assets/images/slider-6.png';
import slider5 from '../../assets/images/slider-7.png';
import slider6 from '../../assets/images/slider-8.png';
import slider7 from '../../assets/images/slider-9.png';
import slider9 from '../../assets/images/slider-2.png';
import slider10 from '../../assets/images/slider-10.png';

function HomeSlider() {
    const sliderRef = useRef();
    const settings = {
        autoplay: true,
        arrows: false,
        slidesToShow: 8,
        dots: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                    initialSlide: 8,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                    initialSlide: 8,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    initialSlide: 6,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <>
            <section className={`appie-video-player-area pt-100 video-player-res `}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-video-player-item">
                                <div className="content text-center">
                                    <h2 className="title heading">
                                        The Benefits of{' '}
                                        <span className="slider-title-eon">eon</span> Longevity
                                        +Plus
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div
                                className="appie-video-player-slider"
                                style={{ position: 'relative' }}
                            >
                                <span
                                    onClick={sliderPrev}
                                    className="prev slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-left" />
                                </span>
                                <Slider ref={sliderRef} {...settings}>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt">
                                                <img src={slider6} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>Reduces inflammation & pain </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt">
                                                <img src={slider9} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>Powerful antioxidant</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt double-logo">
                                                <img src={slider7} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>Supports cardiovascular & respiratory health</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt">
                                                <img src={slider1} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>Improves gastrointestinal health</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt">
                                                <img src={slider4} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>Regulates normal metabolic balance</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt double-logo">
                                                <img src={slider3} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>
                                                    Supports kidney & liver function and health*
                                                    <span className="slider-smaller-font">
                                                        *amongst a subset of clinical study
                                                        participants
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt">
                                                <img src={slider5} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>Reduces prediabetes risk factors</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt">
                                                <img src={slider10} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>Reduces stress</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item-wrap-xzt">
                                        <div className="item border-left-slider-xzt">
                                            <div className="slider-img-xzt">
                                                <img src={slider2} alt="" />
                                            </div>
                                            <div className="slider-content-xzt">
                                                <p>Improves mood, vitaility & sleep</p>
                                            </div>
                                        </div>
                                    </div>
                                </Slider>
                                <span
                                    onClick={sliderNext}
                                    className="next slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-right" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeSlider;
