import React from 'react';
import { Link } from 'react-scroll';
import heroThumbOne from '../../assets/images/banner.png';
import iconOne from '../../assets/images/icon_one.svg';
import iconTwo from '../../assets/images/icon_two.png';
// import iconThree from '../../assets/images/icon_three.png';
import iconFour from '../../assets/images/icon_four.png';
import iconFive from '../../assets/images/icon_five.png';

function HeroSection() {
    return (
        <>
            <section className="appie-hero-area">
                <div className="container-fluid banner-container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="appie-hero-content">
                                <h1 className="appie-title heading">Unlock Your Longevity</h1>
                                <p className="p-font">
                                    When searching for the secret to longevity, we stumbled upon a
                                    centenarian who attributed his many years of good health to a
                                    blend of natural herbs he brewed every day. <br />
                                    <b> We knew we had to share his secret with the world. </b>
                                    Working with the Medical Wellness Association, we enhanced the
                                    blend, and conducted a clinical study that exceeded
                                    expectations, and has doctors questioning{' '}
                                    <b>"could this be the world’s healthiest beverage?"</b>
                                </p>
                                <div className="row align-item-center pt-4 banner-icons-xzt">
                                    <div className="col-2">
                                        <img src={iconOne} alt="" />
                                    </div>
                                    <div className="col-2">
                                        <img src={iconTwo} alt="" />
                                    </div>
                                    <div className="col-2">
                                        <img src={iconFour} alt="" />
                                    </div>
                                    <div className="col-4">
                                        <img className="img-large-xzt" src={iconFive} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="banner-image">
                                <img src={heroThumbOne} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center pb-35">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="banner-last-row">
                                <ul className="mb-10 coming-soon">
                                    <li>
                                        <a href="/signup" target="_blank" className="subcribe">
                                            Sign me up
                                        </a>
                                    </li>
                                    <li>
                                        <Link
                                            to="clinical-study"
                                            className="item-2 hover-links learn-more"
                                        >
                                            Learn more about the clinical study
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="coming-soon-xzt text-center">
                                <p>
                                    <b>Full website & e-commerce coming soon...</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroSection;
