import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import testmonial1 from '../../assets/images/testmonial1.png';
import testmonial2 from '../../assets/images/testmonial2.png';
import testmonial3 from '../../assets/images/testmonial3.png';
import testmonial4 from '../../assets/images/testmonial4.png';
import testmonial5 from '../../assets/images/testmonial5.png';
import testmonial6 from '../../assets/images/testmonial6.png';
import testmonial7 from '../../assets/images/testmonial7.png';
import testmonial8 from '../../assets/images/testmonial8.png';
import testmonial9 from '../../assets/images/testmonial9.png';
import testmonial10 from '../../assets/images/testmonial10.png';

function TestimonialHomeOne() {
    const sliderRef = useRef();
    const settings = {
        autoplay: true,
        arrows: false,
        slidesToShow: 5,
        dots: false,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    initialSlide: 4,
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    };
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <section className="appie-testimonial-area pt-100 pb-100" id="testimonial">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title heading">Testimonials</h3>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-12 testimonial-container">
                        <div className="appie-testimonial-slider" style={{ position: 'relative' }}>
                            <span
                                onClick={sliderPrev}
                                className="prev slick-arrow testimonial-arrow-prev"
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-left" />
                            </span>
                            <Slider ref={sliderRef} dots {...settings} arrows={false}>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial1} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "After consuming{' '}
                                                <span className="testimonial-content-eon">eon</span>{' '}
                                                regularly, my metabolism improved, and my
                                                constipation was relieved." - MS
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial2} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "I realized that I started <b>sleeping better </b>
                                                than I did before I took{' '}
                                                <span className="testimonial-content-eon">eon</span>
                                                , and I felt <b> more energized </b>throughout the
                                                day." - ZM
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial3} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "I consumed{' '}
                                                <span className="testimonial-content-eon">eon</span>{' '}
                                                for 8 weeks and have to admit that my{' '}
                                                <b>general health </b>
                                                felt
                                                <b> much better</b>
                                                ." - ZW
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial4} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "I work out on a regular basis, and when I started
                                                taking{' '}
                                                <span className="testimonial-content-eon">eon</span>{' '}
                                                I realized that my overall performance improved." -
                                                RQ
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial5} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "My body started to <b> feel cleaner </b> the more
                                                time passed as I drank{' '}
                                                <span className="testimonial-content-eon">eon</span>
                                                ." - BS
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial6} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "I realized that my bowel movements became more
                                                regulated after drinking{' '}
                                                <span className="testimonial-content-eon">eon</span>{' '}
                                                ." - SQ
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial7} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "Before I started drinking{' '}
                                                <span className="testimonial-content-eon">eon</span>
                                                , I used to have random pains, but once I started
                                                consuming it that <b> pain started </b>
                                                to <b> fade</b>." - OT
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial8} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "
                                                <span className="testimonial-content-eon">eon</span>{' '}
                                                helped relieve my menstrual cycle pains." - MA
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial9} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "I usually get headaches throughout the day.
                                                However, upon consuming{' '}
                                                <span className="testimonial-content-eon">eon</span>{' '}
                                                my headaches disappeared." - IM
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-img">
                                    <div className="appie-testimonial-item text-center testimonial-xzt">
                                        <div className="author-info title">
                                            <img src={testmonial10} alt="" />
                                        </div>
                                        <div className="text">
                                            <p>
                                                "
                                                <span className="testimonial-content-eon">eon</span>{' '}
                                                is very refreshing, and that's a feeling that
                                                continues with me throughout my day." - OT
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                            <span
                                onClick={sliderNext}
                                className="next slick-arrow testimonial-arrow-next "
                                style={{ display: 'block' }}
                            >
                                <i className="fal fa-arrow-right" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHomeOne;
