/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import Pdf from '../../assets/uploads/white-paper.pdf';
import icon1 from '../../assets/images/icon1.png';
import icon2 from '../../assets/images/icon2.png';
import icon3 from '../../assets/images/icon3.png';
import icon4 from '../../assets/images/icon4.png';
import icon5 from '../../assets/images/icon5.png';
import icon6 from '../../assets/images/icon6.png';

function Services() {
    return (
        <>
            <div className="pb-100" id="clinical-study"></div>
            <section
                className="appie-services-2-area appie-services-8-area pb-100 clinical-study"
                id="clinical-study"
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3 className="appie-title heading">The Clinical Study</h3>
                            <div className="row">
                                <div className="col-xl-6 col-lg-12 col-md-12 clinical-item">
                                    <div className="appie-single-service-2 appie-single-service-about mt-30">
                                        <div className="icon clinical-icon">
                                            <img src={icon1} alt="" />
                                        </div>
                                        <div className="ml-20 clinical-item">
                                            Among the <b>largest clinical studies </b>
                                            in the space.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 clinical-item">
                                    <div className="appie-single-service-2 appie-single-service-about item-2 mt-30">
                                        <div className="icon clinical-icon">
                                            <img src={icon2} alt="" />
                                        </div>
                                        <div className="ml-20 clinical-item">
                                            Designed and underwritten by the{' '}
                                            <b>Medical Wellness Association.</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 clinical-item">
                                    <div className="appie-single-service-2 appie-single-service-about item-3 mt-30">
                                        <div className="icon clinical-icon">
                                            <img src={icon5} alt="" />
                                        </div>
                                        <div className="ml-20 clinical-item">
                                            Blood samples were analyzed by{' '}
                                            <b>independent laboratories </b>
                                            in Germany.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 clinical-item">
                                    <div className="appie-single-service-2 appie-single-service-about item-3 mt-30">
                                        <div className="icon clinical-icon">
                                            <img src={icon3} alt="" />
                                        </div>
                                        <div className="ml-20 clinical-item">
                                            Participants consumed{' '}
                                            <span className="clinical-study-eon">eon</span>{' '}
                                            Longevity +Plus <b>every day for 8 weeks</b>.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 clinical-item">
                                    <div className="appie-single-service-2 appie-single-service-about item-3 mt-30">
                                        <div className="icon clinical-icon">
                                            <img src={icon4} alt="" />
                                        </div>
                                        <div className="ml-20 clinical-item">
                                            <b>Clinical parameters</b> were measured as a baseline
                                            before the first dose, at the midpoint of the study, and
                                            after the 8-week duration.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 clinical-item">
                                    <div className="appie-single-service-2 appie-single-service-about item-3 mt-30">
                                        <div className="icon clinical-icon">
                                            <img src={icon6} alt="" />
                                        </div>
                                        <div className="ml-20 clinical-item">
                                            <b>53 vitals</b> were measured, and{' '}
                                            <b>4,611 data points</b> were analyzed by an independent
                                            doctor and member of the Medical Wellness Association.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 align-item-center text-center mt-20 cta-btn-text">
                                    <p className="service-subtitle">Don’t believe the benefits?</p>
                                    <a href={Pdf} target="_blank" className="cta-services-btn">
                                        Read the clinical study!
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h3 className="appie-title heading heading-2 mb-20">
                                Hear from the Experts
                            </h3>
                            <div className="appie-section-title pb-0">
                                <p className="expert-content">
                                    <span className="italic">
                                        "The most striking result was the multiparametric reduction
                                        in chronic inflammation over the course of weeks of daily
                                        intake. Chronic inflammation lies at the basis of many
                                        (chronic) diseases and therefore must be suppressed to
                                        achieve longevity and health. Restoration of insulin
                                        sensitivity, reduction of plasma lipid markers, and
                                        amelioration of stress were also observed as a result of{' '}
                                        <span className="services-eon">eon</span>."
                                    </span>
                                    <span className="text-center">
                                        <b>
                                            - Dr. Michal Heger; Expert in clinical and translational
                                            research Editor-in-Chief of the Journal of Clinical and
                                            Translational Research Professor at the University of
                                            Amsterdam, Netherlands
                                        </b>
                                    </span>
                                    <span className="italic mt-20">
                                        “With these outstanding clinical results, which far surpass
                                        many prescription medications yet without associated side
                                        effects, it can be argued that{' '}
                                        <span className="services-eon">eon</span> is the healthiest
                                        beverage a person can consume, and should be part of
                                        everyone's personal wellness program to improve quality of
                                        life and even life expectancy."
                                    </span>
                                    <center>
                                        <b>
                                            - Dr. Christopher Breuleux; President of the Medical
                                            Wellness Association
                                        </b>
                                    </center>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Services;
